<template>
  <div>
    <vx-card
      no-shadow
      card-border
      title="Dedicated numbers available">
      <dedicated-number-list
        :key="dedicatedNumberListKey"
        :price="dedicatedNumberAppServiceItemPrice"
        :dedicated-numbers="[]"
        @buy="buyDedicatedNumber"/>

      <small>
        {{ $t('PriceGSTInformation') }}.
        <a href="#">{{ $t('MoreInfoAboutDedicatedNumbers') }}.</a>
      </small>
    </vx-card>

    <payment-checkout-modal
      v-if="numberToBuy"
      v-model="showCheckout"
      :caller-id="{ phoneInternational: numberToBuy }"
      :on-payment-intent-callback="sentPaymentIntent"
      @payment="dedicatedNumberListKey += 1">
    </payment-checkout-modal>
  </div>
</template>

<script>
// Components
import CallerIdListCreateOrEditFromDedicatedNumberList
from '@/views/modules/sender-ids/caller-id/CallerIdListCreateOrEditFromDedicatedNumberList.vue';
import DedicatedNumberPaymentCheckoutModal
from '@/views/modules/sender-ids/caller-id/_components/DedicatedNumberPaymentCheckoutModal.vue';

// Mixins
import dedicatedNumberAppServiceMixin
from '@/views/modules/sender-ids/caller-id/_mixins/dedicated-number-app-service.mixin';
import createDedicatedNumberMixin
from '@/views/modules/sender-ids/caller-id/_mixins/create-dedicated-number.mixin';

/**
 * User account purchases dedicated numbers
 *
 * @module views/modules/user/user-purchases/UserAccountPurchasesDedicatedNumbers
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {number} [dedicatedNumberListKey = 0] - key to refresh dedicated number list
 * @vue-event {void} fetchData - fetch component data
 */
export default {
  name: 'UserAccountPurchasesDedicatedNumbers',
  i18n: {
    messages: {
      en: {
        PriceGSTInformation: 'All pricing is in Australian Dollars (GST excluded)',
        MoreInfoAboutDedicatedNumbers: 'More info about dedicated numbers here',
      },
    },
  },
  components: {
    DedicatedNumberList: CallerIdListCreateOrEditFromDedicatedNumberList,
    PaymentCheckoutModal: DedicatedNumberPaymentCheckoutModal,
  },
  mixins: [dedicatedNumberAppServiceMixin, createDedicatedNumberMixin],
  data() {
    return {
      dedicatedNumberListKey: 0,
    };
  },
  created() {
    if (this.$mq === this.$enums.mqBreakpoints.DESKTOP) {
      window.history.pushState({}, null, '/purchases/dedicated-numbers');
    }

    this.fetchData();
  },
  methods: {
    async fetchData() {
      await this.getDedicatedNumberAppService();
    },
  },
};
</script>

<style scoped>

</style>
